import React from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import SEO from "../../components/seo"
import generalClasses from "./general.module.css"
import Footer from "../../components/footer"

import websiteImage from "../../images/case-studies/odirs.jpg"

import { Link } from "gatsby"
import arrowLeft from "../../images/arrow-left.svg"
import RightIcon from "../../images/arrow-right.inline.svg"
import LeftIcon from "../../images/arrow-left.inline.svg"

const Odirs = props => (
  <HomePageLayout pageTitle="Ondo State Internal Revenue Service" background>
    <SEO title="ODIRS Case Study" />
    <main className={generalClasses.main}>
      <Link to={"/solutions/projects"} className={generalClasses.backLink}>
        <img src={arrowLeft} alt="" className={generalClasses.backLinkImage} />
        Projects
      </Link>
      <section
        className={[generalClasses.caseStudyIntro, generalClasses.section].join(
          " "
        )}
      >
        <div>
          <h1 className={generalClasses.caseStudyHeader}>
            ondo state internal revenue service
          </h1>
          <p className={generalClasses.caseStudyDescription}>
            ODIRS is a website that keeps the public informed on administrative
            process and enable users to carryout revenue task
          </p>
          <p className={generalClasses.caseStudyCategory}>
            UI / UX | WEB DEVELOPMENT
          </p>
          <a
            className={generalClasses.caseStudyLink}
            target="_blank"
            rel="noopener noreferrer"
            href="http://irs.on.gov.ng"
          >
            VISIT WEBSITE
          </a>
        </div>
        <ul className={generalClasses.caseStudyDetails}>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>CLIENT</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              Ondo State government
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>LOCATION</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              Ondo, Nigeria
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>USERS</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              1000 - 1,000,000 Users
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>INDUSTRY</h4>
            <p className={generalClasses.caseStudyDetailContent}>Government</p>
          </li>
        </ul>
      </section>
      <section className={generalClasses.section}>
        <h1 className={generalClasses.meetCaseStudyHeader}>
          Meet the ondo state government
        </h1>
        <p className={generalClasses.meetCaseStudyText}>
          Ondo state is located in the tropical forest of South western part of
          Nigeria, bounded in the north by Ekiti and Kogi states, in the east by
          Edo state, in the west by Osun and Ogun states and in the south by the
          Atlantic ocean. Ondo state is richly blessed with copious natural
          resources waiting to be tapped. The strategic location of the state
          coupled with its peaceful and safe environment makes it very
          attractive to investors.
        </p>
      </section>
      <section
        className={[generalClasses.grid, generalClasses.section].join(" ")}
      >
        <div>
          <h1 className={generalClasses.sectionHeader}>our idea</h1>
          <p className={generalClasses.sectionText}>
            To build a solution that would be used to keep users informed about
            that individual revenue task and how to keep track of it.
          </p>
        </div>
        <div>
          <h1 className={generalClasses.sectionHeader}>RESULTS</h1>
          <p className={generalClasses.sectionText}>
            We finally built a user-friendly website that is easy to use and
            informs the users accurately on the tasks to be carried out.
          </p>
        </div>
      </section>
      <img
        src={websiteImage}
        alt=""
        className={generalClasses.displayImageFull}
      />
      <div className={generalClasses.caseStudyPageLinks}>
        <Link
          to="/case-studies/tech4dev"
          className={generalClasses.caseStudyPageLink}
        >
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <LeftIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>
              PREVIOUS
            </span>
            <span className={generalClasses.caseStudyPageLinkTitle}>
              Tech4dev
            </span>
          </p>
        </Link>
        <Link
          to="/case-studies/sme-upturn"
          className={[
            generalClasses.caseStudyPageLink,
            generalClasses.caseStudyPageLinkRight,
          ].join(" ")}
        >
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>NEXT</span>
            <span className={generalClasses.caseStudyPageLinkTitle}>
              SME Upturn
            </span>
          </p>
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <RightIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
        </Link>
      </div>
    </main>
    <Footer />
  </HomePageLayout>
)

export default Odirs
